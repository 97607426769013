import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import palver from './images/palver.webp';
import { connect, StringCodec, JetStreamClient, KV, QueuedIterator, KvEntry } from 'nats.ws';
import Shell from './components/Shell';
import { cn, getNATSAuthSettings } from './Utils';
import { NATS_ENVIRONMENT } from './config';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './components/ui/select';
import { useToast } from './components/ui/use-toast';
import { Toaster } from './components/ui/toaster';
import { BotList } from './components/bot-list/BotList';
import { BotHost, BotHostMeta } from './types';
import { useRecoilState } from 'recoil';
import { selectedEnvAtom } from './atoms';
import mytraLogo from './images/icons/64.png';
import { AsteriskIcon } from 'lucide-react';

const sc = StringCodec();

function App() {
  const defualtLoadingState = useMemo(() => Object.values(NATS_ENVIRONMENT).reduce((acc, env) => ({ ...acc, [env]: true }), {}), []);
  const [natsEnv, setNatsEnv] = useRecoilState(selectedEnvAtom);
  const [js, setJS] = useState<Record<string, JetStreamClient>>({});
  const [botsKV, setBotsKV] = useState<Record<string, KV>>({});
  const [metaKV, setMetaKV] = useState<Record<string, KV>>({});
  const [bots, setBots] = useState<Record<string, BotDict>>({});
  const [displayBots, setDisplayBots] = useState<Record<string, BotDict>>({});
  const [meta, setMeta] = useState<Record<string, BotHostMeta>>({});
  const [shellJS, setShellJS] = useState<JetStreamClient | null>(null);
  const [shellBot, setShellBot] = useState<BotHost | null>(null);
  const [isLoading, setIsLoading] = useState<Record<string, boolean>>(defualtLoadingState);

  const botsRef = useRef(bots);
  const { toast } = useToast();

  const secondsSinceLastBeat = useCallback((bot: BotHost): number => {
    if (!bot) return 99999;
    const seconds = (new Date().getTime() - bot.updated) / 1000;
    return seconds;
  }, []);

  const editBot = useCallback(
    (botId: string, env: NATS_ENVIRONMENT) => {
      const bot = bots[env][botId] as BotHost;
      if (bot && bot.name) {
        let newName = prompt(`Rename`, bot.name);
        if (newName == null || newName.trim().length < 1) return;
        let bm: any = {};
        if (meta[botId]) {
          bm = meta[botId];
        }
        bm['name'] = newName;
        delete bm['update'];
        metaKV[env].put(botId, JSON.stringify(bm));
      }
    },
    [bots, meta, metaKV]
  );

  const doShell = useCallback(
    (botId: string, natsEnv: NATS_ENVIRONMENT) => {
      console.log(`shell ${botId}`);
      setShellJS(js?.[natsEnv] || null);
      setShellBot(bots[natsEnv]?.[botId] || null);
    },
    [bots, js]
  );

  const onSelectChange = useCallback(
    (value: string) => {
      setBots({});
      setNatsEnv(value as NATS_ENVIRONMENT);
    },
    [setNatsEnv]
  );

  const copyToClipboard = useCallback(async (textToCopy: string) => {
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(textToCopy);
    } else {
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement('textarea');
      textArea.value = textToCopy;
      // Move textarea out of the viewport so it's not visible
      textArea.style.position = 'absolute';
      textArea.style.left = '-999999px';
      document.body.prepend(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
      } catch (error) {
        console.error(error);
      } finally {
        textArea.remove();
      }
    }
  }, []);

  const onCopy = useCallback(
    (text: string) => {
      const copy = async () => {
        console.log(`Copied ${text} to clipboard`);
        await copyToClipboard(text);
        toast({
          description: (
            <span>
              Copied <b>{text}</b> to clipboard
            </span>
          ),
          duration: 1000
        });
      };
      copy();
    },
    [copyToClipboard, toast]
  );

  useEffect(() => {
    if (!natsEnv) setNatsEnv(NATS_ENVIRONMENT.PINE);
  }, [natsEnv, setNatsEnv]);

  // Setup the connection(s) to NATS
  useEffect(() => {
    setIsLoading(defualtLoadingState);
    (async () => {
      if (natsEnv === 'all') {
        const newJS: Record<string, JetStreamClient> = {};
        const newBotsKV: Record<string, KV> = {};
        const newMetaKV: Record<string, KV> = {};
        for (const env of Object.values(NATS_ENVIRONMENT)) {
          try {
            const authSettings = getNATSAuthSettings(env);
            const natsConnection = await connect({
              ...authSettings,
              maxReconnectAttempts: -1,
              reconnect: true
            });
            const js = natsConnection.jetstream();
            const botsKV = await js.views.kv('bots');
            const metaKV = await js.views.kv('bot_meta');
            newJS[env] = js;
            newBotsKV[env] = botsKV;
            newMetaKV[env] = metaKV;
          } catch (error) {
            console.error('Failed to connect to NATS', error);
            setBots(prev => ({ ...prev, [env]: {} }));
          }
        }
        setJS(newJS);
        setBotsKV(newBotsKV);
        setMetaKV(newMetaKV);
      } else {
        try {
          const authSettings = getNATSAuthSettings(natsEnv);
          const natsConnection = await connect({
            ...authSettings,
            maxReconnectAttempts: -1,
            reconnect: true
          });
          const jetStream = natsConnection.jetstream();
          const newBotsKV = await jetStream.views.kv('bots');
          const newMetaKV = await jetStream.views.kv('bot_meta');
          setJS(prev => ({ ...prev, [natsEnv]: jetStream }));
          setBotsKV(prev => ({ ...prev, [natsEnv]: newBotsKV }));
          setMetaKV(prev => ({ ...prev, [natsEnv]: newMetaKV }));
        } catch (error) {
          console.error('Failed to connect to NATS', error);
          setBots({});
        }
      }

      // clear the bots after 3 seconds if we don't have any
      setTimeout(() => {
        setBots(bots => (bots === null ? {} : bots));
      }, 3000);
    })();
  }, [defualtLoadingState, natsEnv]);

  // Watch the bots KV(s)
  useEffect(() => {
    const watchKVDict: Record<string, QueuedIterator<KvEntry>> = {};
    if (natsEnv === 'all') {
      for (const env of Object.values(NATS_ENVIRONMENT)) {
        if (botsKV[env]) {
          const start = async () => {
            const newBots: Record<string, BotHost> = {};
            watchKVDict[env] = await botsKV[env].watch();
            for await (const e of watchKVDict[env]) {
              try {
                newBots[e.key] = e.json();
                const bot = newBots[e.key] as BotHost;
                bot.updated = e.created.getTime();
                bot.id = e.key;
              } catch (error) {
                console.log(`ignoring ${e.key} non-json value `, e.value, sc.decode(e.value));
              }
              setBots(prev => ({ ...prev, [env]: newBots }));
              if (Object.keys(newBots).length > 0) {
                setIsLoading(prev => ({ ...prev, [env]: false }));
              }
            }
          };
          start();
        }
      }
    } else if (botsKV[natsEnv]) {
      const start = async () => {
        const newBots: Record<string, BotHost> = {};
        watchKVDict[natsEnv] = await botsKV[natsEnv].watch();
        for await (const e of watchKVDict[natsEnv]) {
          try {
            newBots[e.key] = e.json();
            const bot = newBots[e.key] as BotHost;
            bot.updated = e.created.getTime();
            bot.id = e.key;
          } catch (error) {
            console.log(`ignoring ${e.key} non-json value `, e.value, sc.decode(e.value));
          }
          setBots(prev => ({ ...prev, [natsEnv]: newBots }));
          if (Object.keys(newBots).length > 0) {
            setIsLoading(prev => ({ ...prev, [natsEnv]: false }));
          }
        }
      };
      start();
    }

    return () => Object.values(watchKVDict).forEach(w => w.stop());
  }, [botsKV, defualtLoadingState, natsEnv]);

  // Watch the meta KV(s)
  useEffect(() => {
    const watchKVDict: Record<string, QueuedIterator<KvEntry> | null> = {};
    if (natsEnv === 'all') {
      for (const env of Object.values(NATS_ENVIRONMENT)) {
        if (metaKV[env]) {
          const start = async () => {
            const newMeta: { [key: string]: any } = {};
            watchKVDict[env] = await metaKV[env].watch();
            if (watchKVDict[env]) {
              for await (const e of watchKVDict[env] as QueuedIterator<KvEntry>) {
                try {
                  newMeta[e.key] = e.json();
                  newMeta[e.key].updated = e.created;
                } catch (error) {
                  console.error(`ignoring ${e.key} non-json value `, e.value, sc.decode(e.value));
                }
                setMeta({ ...newMeta });
              }
            }
          };
          start();
        }
      }
    } else if (metaKV) {
      const start = async () => {
        const newMeta: { [key: string]: any } = {};
        const kv = metaKV[natsEnv];
        if (kv) {
          watchKVDict[natsEnv] = await metaKV[natsEnv].watch();
          if (watchKVDict[natsEnv]) {
            for await (const e of watchKVDict[natsEnv] as QueuedIterator<KvEntry>) {
              try {
                newMeta[e.key] = e.json();
                newMeta[e.key].updated = e.created;
              } catch (error) {
                console.error(`ignoring ${e.key} non-json value`, e.value, sc.decode(e.value));
              }
              setMeta({ ...newMeta });
            }
          }
        }
      };
      start();
    }
    return () => Object.values(watchKVDict).forEach(w => w?.stop());
  }, [metaKV, natsEnv, setMeta]);

  useEffect(() => {
    if (natsEnv === 'all') {
      for (const env of Object.values(NATS_ENVIRONMENT)) {
        const botList = { ...bots[env] };
        if (botList) {
          Object.entries(botList)
            .filter(([, bot]) => typeof bot !== 'string')
            .forEach(([botId, bot]) => {
              bot.alive = secondsSinceLastBeat(bot) < 8;
              // we we have a name for it?
              bot.name = botId.slice(-4);
              if (meta[botId]) {
                bot.name = meta[botId].name || bot.name;
              }
            });
          setDisplayBots(prev => ({ ...prev, [env]: botList }));
        }
      }
    } else {
      const botList = { ...bots[natsEnv] };
      if (botList) {
        Object.entries(botList)
          .filter(([, bot]) => typeof bot !== 'string')
          .forEach(([botId, bot]) => {
            bot.alive = secondsSinceLastBeat(bot) < 8;
            // we we have a name for it?
            bot.name = botId.slice(-4);
            if (meta[botId]) {
              bot.name = meta[botId].name || bot.name;
            }
          });
        setDisplayBots(prev => ({ ...prev, [natsEnv]: botList }));
      }
    }
  }, [bots, meta, natsEnv, secondsSinceLastBeat]);

  // Add bots to the document for debugging
  useEffect(() => {
    botsRef.current = bots;
    document.bots = bots;
  }, [bots]);

  // If after 3 seconds there are no bots, set loading to false
  useEffect(() => {
    setTimeout(() => {
      if (natsEnv === 'all') {
        const newIsLoading: Record<string, boolean> = {};
        for (const env of Object.values(NATS_ENVIRONMENT)) {
          if (Object.keys(botsRef.current[env] || {}).length === 0) {
            // setIsLoading({ ...isLoading, [env]: false });
            newIsLoading[env] = false;
          }
        }
        setIsLoading(prev => ({ ...prev, ...newIsLoading }));
      } else {
        setIsLoading(prev => {
          let newIsLoading = { ...prev };
          const isLoading = !prev[natsEnv];
          if (Object.keys(botsRef.current[natsEnv] || {}).length === 0 && isLoading) {
            newIsLoading = { ...prev, [natsEnv]: false };
          }
          return newIsLoading;
        });
      }
    }, 3000);
  }, [natsEnv]);

  return (
    <div className='App'>
      {shellBot === null ? (
        <>
          <header className={cn('App-header fixed xl:w-1/5 z-[3] lg:block', 'w-10 lg:w-1/6', 'top-4 right-4 lg:right-6 lg:top-6')}>
            <h1 className='text-4xl font-bold text-center'>
              <img className='rounded-md lg:rounded-lg' src={palver} alt='Palver' />
              <span className='hidden lg:inline'>Palver</span>
            </h1>
          </header>

          <div className='content mt-12'>
            <div className='fixed top-0 left-0 right-0 bg-secondary/50 backdrop-blur z-[2] flex items-center'>
              <img src={mytraLogo} alt='Mytra Logo' className='w-8 h-8 ml-4' />
              <Select value={natsEnv || NATS_ENVIRONMENT.PINE} onValueChange={onSelectChange}>
                <SelectTrigger className='w-[180px] my-4 ml-4'>
                  <SelectValue placeholder='Select environment' />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value={'all'} title='All Environments'>
                    <AsteriskIcon className='p-0' />
                  </SelectItem>
                  <SelectItem value={NATS_ENVIRONMENT.PINE}>{NATS_ENVIRONMENT.PINE}</SelectItem>
                  <SelectItem value={NATS_ENVIRONMENT.POT}>{NATS_ENVIRONMENT.POT}</SelectItem>
                  <SelectItem value={NATS_ENVIRONMENT.TEST}>{NATS_ENVIRONMENT.TEST}</SelectItem>
                </SelectContent>
              </Select>
            </div>
            {natsEnv === 'all' ? (
              <div className='grid grid-cols-1 gap-4'>
                {Object.values(NATS_ENVIRONMENT).map((env, i) => (
                  <BotList
                    key={env}
                    natsEnv={env as NATS_ENVIRONMENT}
                    bots={displayBots[env]}
                    onCopy={onCopy}
                    editBot={editBot}
                    doShell={doShell}
                    locationRow={true}
                    headerRow={i !== 0}
                    isLoading={isLoading[env]}
                  />
                ))}
              </div>
            ) : (
              <BotList
                bots={displayBots[natsEnv]}
                onCopy={onCopy}
                editBot={editBot}
                doShell={doShell}
                natsEnv={natsEnv}
                locationRow={true}
                headerRow={false}
                isLoading={isLoading[natsEnv]}
              />
            )}
          </div>
        </>
      ) : (
        <Shell bot={shellBot} close={() => setShellBot(null)} js={shellJS} />
      )}
      <Toaster />
    </div>
  );
}

export default App;

export type Environment = 'all' | NATS_ENVIRONMENT;
export type BotDict = Record<string, BotHost>;
