import { credsAuthenticator } from 'nats.ws';
import { NATS_ENVIRONMENTS } from './config';
import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

document.hash = window.location.hash
  .slice(1)
  .split('&')
  .map(v => v.split('='))
  .reduce((pre, [key, value]) => {
    try {
      value = JSON.parse(decodeURIComponent(value));
    } catch (_) {}
    return { ...pre, [key]: value };
  }, {});

export const clone = (value: any) => {
  if (!value) return value;
  return JSON.parse(JSON.stringify(value));
};

export const remember = (key: string, value: any) => {
  let toStore = value;
  if (typeof toStore !== 'string') {
    toStore = JSON.stringify(toStore);
  }
  localStorage.setItem(key, toStore);
};

export const recall = (key: string, defaultValue?: any): any => {
  let value = localStorage.getItem(key);
  try {
    if (value) value = JSON.parse(value);
  } catch (_) {}
  if ((value === null || value === undefined) && defaultValue !== undefined) {
    value = defaultValue;
  }
  return value;
};

export const forget = (key: string) => {
  localStorage.removeItem(key);
};

export const sessionRemember = (key: string, value: any) => {
  let toStore = value;
  if (typeof toStore !== 'string') {
    toStore = JSON.stringify(toStore);
  }
  sessionStorage.setItem(key, toStore);
};

export const sessionRecall = (key: string, defaultValue?: any): any => {
  if (key === 'storageVolume') {
    // if we have a conflict, use that sv
    if (document.hash.visualize) {
      return document.hash.visualize.sv;
    }
  }
  let value = sessionStorage.getItem(key);
  try {
    if (value) value = JSON.parse(value);
  } catch (_) {}
  if ((value === null || value === undefined) && defaultValue !== undefined) {
    value = defaultValue;
  }
  return value;
};

export const sessionForget = (key: string) => {
  localStorage.removeItem(key);
};

export const objectIsEmpty = (o: any) => Object.keys(o).length === 0;

export const uriToId = (uri: string): string => {
  return uri.split(':')[0];
};
export const idToCoords = (id: string): { x: number; y: number; z: number } => {
  const i = uriToId(id)
    .split('.')
    .map((v: any) => parseInt(v));
  return {
    x: i[0],
    y: i[1],
    z: i[2]
  };
};
export const coordsToId = ({ x, y, z }: { x: number; y: number; z: number }) => `${x}.${y}.${z}`;

export const secondsToHMS = (seconds: number) => {
  return new Date(seconds * 1000).toISOString().slice(11, 19);
};
export const renderFloat = (n?: number | undefined | null, decimalDigits?: number): string => {
  if (!n) return '';
  if (!decimalDigits) decimalDigits = 2;
  if (n === 0.0) {
    return '';
  }
  return n.toFixed(decimalDigits);
};

export const getNATSAuthSettings = (name: string) => {
  let result: any = null;
  const settings = NATS_ENVIRONMENTS[name];

  if (settings) {
    if (settings.creds) {
      const authenticator = credsAuthenticator(new TextEncoder().encode(settings.creds));
      result = { servers: settings.servers, authenticator };
    } else {
      result = { ...settings };
    }
  }

  return result;
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export type PropsWithClassName<Props = unknown> = {
  className?: string;
} & Props;
