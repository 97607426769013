/* Environments */

export const NATS_TEST_URL = 'wss://secure-nats-test.artym.net:9222';
export const NATS_PINE_URL = 'wss://secure-nats-pine.artym.net:9222';
export const NATS_POT_URL = 'wss://secure-nats-pot.artym.net:9222';

export enum NATS_ENVIRONMENT {
  PINE = 'nats-pine',
  POT = 'nats-pot',
  TEST = 'nats-test'
}

let testCreds = null;
let pineCreds = null;
let potCreds = null;

try {
  testCreds = atob(`${process.env.REACT_APP_NATS_TEST_CREDS_BASE_64}`);
  pineCreds = atob(`${process.env.REACT_APP_NATS_PINE_CREDS_BASE_64}`);
  potCreds = atob(`${process.env.REACT_APP_NATS_POT_CREDS_BASE_64}`);
} catch {
  console.error('Failed to load NATS credentials from environment');
}

export const NATS_ENVIRONMENTS: { [key: string]: any } = {
  [NATS_ENVIRONMENT.TEST]: {
    servers: NATS_TEST_URL,
    creds: testCreds
  },
  [NATS_ENVIRONMENT.PINE]: {
    servers: NATS_PINE_URL,
    creds: pineCreds
  },
  [NATS_ENVIRONMENT.POT]: {
    servers: NATS_POT_URL,
    creds: potCreds
  }
};
